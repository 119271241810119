const VWO_GEO_COUNTRY = {
  Chile: "chile",
  Argentina: "argentina",
  "United States": "unitedstates",
  Brazil: "brazil",
  Peru: "peru",
};

export function getIpStackInfo() {
  return new Promise((resolve) => {
    const SESSION_KEY = "geo-localization-client";

    const saveAndResolve = (cn) => {
      const response = { country: VWO_GEO_COUNTRY[cn] || "chile" };
      window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(response));
      resolve(response);
    };

    let checkVWO = setInterval(() => {
      if (window?.VWO?._?.allSettings?.dataStore?.plugins?.GEO) {
        clearInterval(checkVWO);
        const { cn } = window.VWO._.allSettings.dataStore.plugins.GEO;
        saveAndResolve(cn);
      }
    }, 100);

    setTimeout(() => {
      clearInterval(checkVWO);
      saveAndResolve("chile");
    }, 5000);
  });
}
